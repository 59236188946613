import React, {useState} from 'react'
import bulkIcons from './bulkIcons.js'

function TableRow(props) {

  //Hooks
  const [rowId, setRowId] = useState("break");

  const d = new Date();
  let day = d.getDate();
  let month = parseInt(d.getMonth()) + 1;
  let year = d.getFullYear();
  const total = localStorage.getItem("totalPomos");
  const dailyPomos = [];
  const pomoKey = "" + year + "" + month + "" + props.day;

  let Badge = bulkIcons["GiFluffySwirl"]["component"];

  for (let index = 0; index < total; index++) {
    const intentKey = localStorage.getObject("pomodoros");
    if(intentKey[index]["dateKey"]==pomoKey) {
      const currKey = (intentKey[index]["focusIcon"]["currIcon"] != undefined) ? intentKey[index]["focusIcon"]["currIcon"] : "GiFluffySwirl";
      Badge = bulkIcons[currKey]["component"];
      const color = bulkIcons[currKey]["color"];

      dailyPomos.push(<>
        <span key={index} className="red-swirl" title={JSON.stringify(intentKey[index]['intention']).replace(/['"]+/g, '')}> 
          <Badge style={{fill: color}}  />
        </span>
        </>
      );
    }
  }
  if (day == props.day) day = <strong className="today">{props.day}</strong>;
  else day = <>{props.day}</>;

  return (
    <tr>
      <td>{day}</td>
      <td>{dailyPomos}</td>
    </tr>
  );
}
export default TableRow;
