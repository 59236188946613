import React, { useState } from "react";
import SelectPopup from "./SelectPopup.js";
import bulkIcons from "./bulkIcons.js"


function FocusIcon(props) {

  const [selectActive, setSelectActive] = useState(false);
  const [currIcon, setCurrIcon] = useState(props.currIcon);

  const handleIconPopup = () => {
    if (selectActive) setSelectActive(false);
    else setSelectActive(true);
  };

  const handleChangeIcon = (props) => {
    console.log("icon changed: " + props);
    setCurrIcon(props);
  };

  let FIcon = bulkIcons[currIcon]["component"];
  const color = bulkIcons[currIcon]["color"]

  return (
    <div
      className={`interactable focus-icon select-active-${selectActive}`}
      onClick={handleIconPopup}
    >
    <FIcon style={{fill: color}} />
      <div className="select-popup">
        <SelectPopup onChangeIcon={handleChangeIcon} changeParent={props.changeParent} />
      </div>
    </div>
  );
}
export default FocusIcon;
