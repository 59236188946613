import bulkIcons from './bulkIcons.js'

function SelectPopup(props) {

	let FocusChoice = bulkIcons["GiFluffySwirl"][0];
	const iconKeys = Object.keys(bulkIcons);
	const selectPopupIconHolder = [];

	//console.log("bulkIcons: " + bulkIcons[iconKeys[0]]["component"]);

	for(var i = 0; i<iconKeys.length; i++) {
		FocusChoice = bulkIcons[iconKeys[i]]["component"];
		console.log("FocusChoice: " + FocusChoice);

		const icon = iconKeys[i];
		selectPopupIconHolder.push(<span onClick={() => {
				props.onChangeIcon(icon);
				props.changeParent(icon);
			}} >
			<FocusChoice style={{fill: bulkIcons[iconKeys[i]]["color"]}}  />
		</span>)
	}

	//console.log("bulkIcons[iconKeys[0]]['component']: " + bulkIcons[iconKeys[0]]["component"] );
	//console.log("bulkIcons[iconKeys[2]]['component']: " + bulkIcons[iconKeys[2]]["component"] );
	return <>
		<div className="select-popup-wrapper">
			<div className="select-popup-inner">
				<h2>Choose your focus badge</h2>
				{selectPopupIconHolder}
			</div>
		</div>
	</>
}
export default SelectPopup;