import React from 'react'
import Editable from "./Editable.js";

function Intention(props) {

	return <div>
			<Editable
				text={props.task}
				placeholder="Click here to write your focus intention"
				type="input"
				className="editable-intention"
			>
				<input
					type="text"
					name="task"
					placeholder="Click here to write your focus intention"
					value={props.task}
					onChange={(e) => props.setIntention(e.target.value)}
				/>
			</Editable>
		</div>
}
export default Intention;