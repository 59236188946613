import TableRow from "./TableRow.js";

function Calendar(props) {
  const tableRows = [];

  function getDaysInMonth(month, year) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month+1, 0).getDate();
    // Here January is 0 based
    // return new Date(year, month+1, 0).getDate();
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  const month = d.getMonth();
  const year = d.getYear();
  const day = d.getDate();
  const total = getDaysInMonth(month, year) + 1;

  //create table rows for just this month
  for (let index = 1; index < total; index++) {
    tableRows.push(<TableRow key={index} day={index} />);
  }
  return (
    <div className="calendar">
      <h2 className="month-heading">{monthNames[d.getMonth()]}</h2>
      <table className="calendar-table">
        <thead>
          <tr>
            <th>Day</th>
            <th>POMO</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
}
export default Calendar;
