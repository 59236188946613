import bulkIcons from './bulkIcons.js'

function Information() {
 let FIcon = bulkIcons["Fitness"]["component"];
	return <>
	<div className="information-sidebar">
		<div>
			<h2>Focus Technique</h2>
			<p>This focus timer uses the <a target="_blank" href="https://en.wikipedia.org/wiki/Pomodoro_Technique">pomodoro technique</a> 
			&nbsp;as a method for improving focus. By working with a single intention, you can get more done in less time.</p>
			<h2>How to</h2>
			<p>One common method is to work for 25 minutes, take a 5 minute break, and repeat two more times. On the four time,
			work 25 minutes, but then take a longer break for 10-30 minutes.</p>
			<ol>
				<li>Set an intention</li>
				<li>Work for 25 minutes. </li>
				<li>Take a 5-10 minute break</li>
			</ol>
			<h2>Focus Badge</h2>
			<p>Choose your focus icon e.g. <FIcon /> to display badges on your calendar to visualize your pomodoros!</p>
		</div>
	</div>
	</>
}
export default Information;