
//react icons
import { GiFluffySwirl } from 'react-icons/gi'
import { GiAbstract011 } from "react-icons/gi";
import { TbWriting } from 'react-icons/tb'
import { TbYoga } from 'react-icons/tb'
import { IoGameController } from 'react-icons/io5'
import { FaDragon } from 'react-icons/fa'
import { GiWeightLiftingUp } from 'react-icons/gi'
import { GiSecretBook } from 'react-icons/gi'
import { FaHeartbeat } from 'react-icons/fa'
import { GiHeartBottle } from 'react-icons/gi'
import { GiHeartInside } from 'react-icons/gi'
import { GiBrain } from 'react-icons/gi'
import { FaLevelUpAlt } from 'react-icons/fa'
import { GiAbstract007 } from 'react-icons/gi'
import { GiAntibody } from 'react-icons/gi'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { SiWebmoney } from 'react-icons/si'
import { GiReceiveMoney } from 'react-icons/gi'
import { GiPayMoney } from 'react-icons/gi'
import { GiEarthAmerica } from 'react-icons/gi'
import { FaHandsHelping } from 'react-icons/fa'
import { logojavascript } from '@iconify/react'
import { Icon } from '@iconify/react';

//iconify
import lightBulb from '@iconify/icons-emojione/light-bulb';
import fitnessIcon from '@iconify/icons-ion/fitness';
import calendarMonth from '@iconify/icons-material-symbols/calendar-month';
import questionMarkCircle from '@iconify/icons-mdi/question-mark-circle';

//#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#", "#f3e1eb", "#f6c4e1", "#f79cd4"
const bulkIcons = {
  "Info" : {
    "component" : () => {return <><Icon icon={questionMarkCircle} /></>}, //iconify syntax
    "color" : "#f3e1eb"
  },
  "CalendarMonth" : {
    "component" : () => {return <><Icon icon={calendarMonth} /></>}, //iconify syntax
    "color" : "#f3e1eb"
  },
  "Fitness" : {
    "component" : () => {return <><Icon icon={fitnessIcon} /></>}, //iconify syntax
    "color" : "#f3e1eb"
  },
  "LightBulb" : {
    "component" :  () => {return <><Icon icon={lightBulb} /></>},
    "color" : "#f3e1eb"
  },
  "GiFluffySwirl" : { 
    "component" : GiFluffySwirl, //react icons syntax
    "color" : "#023fa5"
  },
  "GiAbstract011" : { 
    "component" : GiAbstract011, 
    "color" : "#7d87b9"
  },
  "TbWriting" : { 
    "component" : TbWriting, 
    "color" : "#bec1d4"
  },
  "IoGameController" : { 
    "component" : IoGameController, 
    "color" : "#d6bcc0"
  },
  "TbYoga" : { 
    "component" : TbYoga, 
    "color" : "#bb7784"
  },
  "FaDragon" : { 
    "component" : FaDragon, 
    "color" : "#8e063b"
  },
  "GiWeightLiftingUp" : { 
    "component" : GiWeightLiftingUp, 
    "color" : "#4a6fe3"
  },
  "GiSecretBook" : { 
    "component" : GiSecretBook, 
    "color" : "#8595e1"
  },
  "FaHeartbeat" : { 
    "component" : FaHeartbeat, 
    "color" : "#b5bbe3"
  },
  "GiHeartBottle" : { 
    "component" : GiHeartBottle, 
    "color" : "#e6afb9"
  },
  "GiHeartInside" : { 
    "component" : GiHeartInside, 
    "color" : "#e07b91"
  },
  "GiBrain" : { 
    "component" : GiBrain, 
    "color" : "#d33f6a"
  },
  "FaLevelUpAlt" : { 
    "component" : FaLevelUpAlt, 
    "color" : "#11c638"
  },
  "GiAbstract007" : { 
    "component" : GiAbstract007, 
    "color" : "#8dd593"
  },
  "GiAntibody" : { 
    "component" : GiAntibody, 
    "color" : "#c6dec7"
  },
  "FaMoneyBillAlt" : { 
    "component" : FaMoneyBillAlt, 
    "color" : "#ead3c6"
  },
  "SiWebmoney" : { 
    "component" : SiWebmoney, 
    "color" : "#f0b98d"
  },
  "GiReceiveMoney" : { 
    "component" : GiReceiveMoney, 
    "color" : "#ef9708"
  },
  "GiPayMoney" : { 
    "component" : GiPayMoney, 
    "color" : "#0fcfc0"
  },
  "GiEarthAmerica" : { 
    "component" : GiEarthAmerica, 
    "color" : "#9cded6"
  },
  "FaHandsHelping" : { 
    "component" : FaHandsHelping, 
    "color" : "#d5eae7"
  },
}

export default bulkIcons;