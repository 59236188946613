import logo from "./logo.svg";
import "./App.css";
import React, { useState, useRef, useEffect } from "react";
import Swirl from "./images/swirl.png";
import useSound from "use-sound";
import playIcon from "./images/play.svg";
import pauseIcon from "./images/pause.svg";
import alarmReward from "./alarm-reward-sound.wav";
import Calendar from "./Calendar.js";
import FocusIcon from "./FocusIcon.js";
import ResetContainer from "./ResetContainer.js";
import Intention from "./Intention.js";
import Information from "./Information.js";
import bulkIcons from './bulkIcons.js'

const App = () => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);
  Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
  };

  Storage.prototype.getObject = function (key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
  };

  const [currIcon, setCurrIcon] = useState("GiFluffySwirl");

  // React Hooks
  const [timer, setTimer] = useState("25:00");
  const [active, setActive] = useState(false);
  const [task, setTask] = useState("");
  const [mode, setMode] = useState("break");
  const [activeCalendar, setActiveCalendar] = useState(false);
  const [activeInfo, setActiveInfo] = useState(false);

  const d = new Date();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();
  const dateKey = "" + year + "" + month + "" + day;

  //store data by their date timestamp (dateKey) as the key
  const currStore = localStorage.getItem(dateKey);
  const currTotal = localStorage.getItem("totalPomos");
  const currPomos = localStorage.getObject("pomodoros");

  if (currPomos === null) {
    localStorage.setObject("pomodoros", []);
  }
  if (currStore === null) {
    localStorage.setObject(dateKey, 0);
  }
  if (currTotal === null) {
    localStorage.setItem("totalPomos", 0);
  }

  //const [pomos, setPomos] = useState(parseInt(localStorage.getItem(dateKey)));

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    if (active == true) document.title = `🔴${timer}` + ` – ${task}`;
    else document.title = `▐ ▌${timer}`;
  });

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);

    const time =
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds);

    if (mode == "work" && time == "00:00") {
      //new pomodoro to save!

      const numPomosToday =
        parseInt(localStorage.getItem("" + year + "" + month + "" + day)) + 1;
      localStorage.setItem(dateKey, numPomosToday);
      localStorage.setItem(
        "totalPomos",
        parseInt(localStorage.getItem("totalPomos")) + 1
      );

      const totalPomos = localStorage.getItem("totalPomos");

      const newPomo = {
        focusIcon: { currIcon },
        dateKey: dateKey,
        pomoId: totalPomos,
        intention: task,
      };
      const pomos = localStorage.getObject("pomodoros");
      pomos.push(newPomo);

      localStorage.setObject("pomodoros", pomos);
      //userObject =

      //clear and reset , change to pause mode, play sound effect
      if (Ref.current) clearInterval(Ref.current);
      setActive(false);
      setMode("work");
      clearTimer();
      resetTimer("25:00");
      play();
    }
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        //(hours > 9 ? hours : '0' + hours) + ':' +
        time
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    //setTimer('25:00');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const resetTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer(e);

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time = 25 minutes * 60 seconds
    deadline.setSeconds(deadline.getSeconds() + 25 * 60);
    return deadline;
  };
  const getTimeLeft = (e) => {
    let deadline = new Date();
    console.log("timer: " + timer);
    let mins = parseInt(timer.substring(0, 2));
    console.log("mins: " + mins);
    let secs = parseInt(timer.substring(3, 5));
    console.log("secs: " + secs);
    deadline.setSeconds(deadline.getSeconds() + (mins * 60 + secs));
    return deadline;
  };
  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    //clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickPomo = () => {
    setActive(false);
    setMode("work");
    resetTimer("25:00");
  };
  const onClickShort = () => {
    setActive(false);
    setMode("break");
    resetTimer("05:00");
  };
  const onClickLong = () => {
    setActive(false);
    setMode("break");
    resetTimer("10:00");
  };
  const onClickPlayPause = (e) => {
    if (active == false) {
      //press play
      clearTimer(getTimeLeft());
      setActive(true);
    } else {
      //press pause
      if (Ref.current) clearInterval(Ref.current);
      setActive(false);
    }
  };
  const PlayButton = (props) => {
    const [play] = useSound(alarmReward, {
      volume: 0, //sound disabled
      onplay: () => {
        onClickPlayPause(props);
      },
    });
    return (
      <button className="play-button" onClick={play}>
        <img src={active ? pauseIcon : playIcon} />
      </button>
    );
  };
  const handleChangeIcon = (props) => {
    console.log("icon changed: " + props);
    setCurrIcon(props);
  };
  const [play, { stop, isPlaying }] = useSound(alarmReward);
  const CalendarIcon = bulkIcons["CalendarMonth"]["component"];
  const InfoIcon = bulkIcons["Info"]["component"];
  const handleCalendarClick = () => {
    if(activeCalendar==false) {
      setActiveCalendar(true); 
    } else {
      setActiveCalendar(false)
    }
  };
  const handleInfoClick = () => {
    if(activeInfo==false) {
      setActiveInfo(true); 
      console.log("active info true")
    } else {
      setActiveInfo(false)
    }
  };
  return (
    <div
      className={`App body-timer-active-${active} calendar-active-${activeCalendar} info-active-${activeInfo}`}
    >
      <Calendar dateKey={dateKey} />
      <div className={`interactable focus-timer timer-active-${active}`}>
        <div className="focus-timer-inner-wrap">
          <h1>Focus Timer</h1>
          <Intention setIntention={setTask} task={task} />
          <FocusIcon currIcon={currIcon} changeParent={handleChangeIcon} />
          <PlayButton />
          <h2 className="focus-timer-heading">{timer}</h2>
          <ResetContainer
            onClickPomo={onClickPomo}
            onClickShort={onClickShort}
            onClickLong={onClickLong}
          />
        </div>
      </div>
      <Information />
      <div className="helper-icons">
        <div onClick={handleCalendarClick}>
          <CalendarIcon />
        </div>
        <div onClick={handleInfoClick}>
          <InfoIcon />
        </div>
      </div>
    </div>
  );
};

export default App;
