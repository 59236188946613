
function ResetContainer(props) {

	return <div className="reset-container">
            <span onClick={props.onClickPomo}>
              <span className="reset-icon">25</span> POMO
              <br />
            </span>
            <span onClick={props.onClickShort}>
              <span className="reset-icon">5</span> SHRT
            </span>
            <span onClick={props.onClickLong}>
              <span className="reset-icon">10</span> LONG
            </span>
          </div>
}
export default ResetContainer;